import { graphql } from "gatsby"
import React from "react"
import Layout from "../components/layout"

interface SiteMetadata {
  title: string
  subtitle: string
}
interface SiteConfiguration {
  siteMetadata: SiteMetadata
}

interface SiteData {
  site: SiteConfiguration
}

interface SiteProps {
  data: SiteData
}

export default (props: SiteProps) => (
  <Layout>
    <h1>About {props.data.site.siteMetadata.title}</h1>
  </Layout>
)

export const query = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`
